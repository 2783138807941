<template>
  <v-card class="rounded-lg py-2 shadow-regular">
    <v-card-text class="pb-0">
      <v-container>
        <v-row>
          <v-col class="mb-3 section-title" cols="auto">
            {{ $t('Title_.subTitle.bankDetails') }}
          </v-col>
        </v-row>
        <v-row data-testid="bank-details">
          <v-col cols="6">
            <RegularTextInput
              data-testid="bank-details-payee-field"
              :label="$t('Msg_.payee')"
              :disabled="isDisabled"
              v-model="bankDetails.payee"
            />
          </v-col>
          <v-col cols="6">
            <DropdownInput
              data-testid="bank-details-payment-method-dropdown"
              :label="$t('Msg_.paymentMethod')"
              :disabled="isDisabled"
              :clearable="false"
              :items="['Card', 'Cash', 'Gold']"
              v-model="bankDetails.paymentMethod"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pt-0">
            <RegularTextInput
              data-testid="bank-details-iban-field"
              :label="$t('Msg_.iban')"
              :disabled="isDisabled"
              v-model="bankDetails.iban"
            />
          </v-col>
          <v-col cols="6" class="pt-0">
            <RegularTextInput
              data-testid="bank-details-bic-field"
              :label="$t('Msg_.bic')"
              :disabled="isDisabled"
              v-model="bankDetails.bic"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pt-0">
            <RegularTextInput
              data-testid="bank-details-bank-code-field"
              :label="$t('Msg_.bank')"
              :disabled="isDisabled"
              v-model="bankDetails.bankCode"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-container>
        <v-row>
          <v-col cols="6" v-if="isDisabled">
            <v-btn
              class="main-action-btn ml-2"
              data-testid="bank-details-edit-information-btn"
              @click="toggleDisabled()"
            >
              {{ !isDisabled ? 'Save changes' : 'Edit information' }}
            </v-btn>
          </v-col>
          <v-col cols="6" v-if="!isDisabled">
            <v-btn
              data-testid="bank-details-cancel-btn"
              class="cancel-variant-btn ml-2"
              @click="toggleDisabled()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="main-action-btn ml-2"
              data-testid="bank-details-save-changes-btn"
              @click="toggleDisabled()"
            >
              {{ !isDisabled ? 'Save changes' : 'Edit information' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import request from '@/helpers/httpClient';
import { ref, defineProps, onBeforeUnmount } from 'vue';

// --- props ---
const props = defineProps({
  currentProfileId: {
    type: String,
    default: null,
    required: false,
  },
});

// --- Variables ---
const isDisabled = ref(true);
const bankDetails = ref({
  payee: null,
  paymentMethod: null,
  iban: null,
  bic: null,
  bankCode: null,
});

// --- Methods ---
const getProfileBankInformation = async () => {
  const id = props.currentProfileId || 'fae285b0-362f-44b1-ae21-a94a24ad40a8';

  const params = {
    endpoint: `v2/api/employee/${id}/bank`,
    configs: {
      method: 'GET',
    },
  };

  try {
    const data = await request.service(params);
    Promise.resolve(data);
    bankDetails.value = data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const toggleDisabled = () => {
  isDisabled.value = !isDisabled.value;
};

// --- Lifecycle hooks ---

// --- Created ---
getProfileBankInformation();

// --- Before destroy ---
onBeforeUnmount(() => {});

// --- Computed ---

// const btnBankDetailsName = computed(() => {
//   const t = this.$t.bind(this);
//   return !this.isDisabled
//     ? t('Action_.buttons.saveChanges')
//     : t('Action_.buttons.editInformation');
// });

// const paymentMethods = computed(() => {
//   const t = this.$t.bind(this);
//   const methods = this.$store.state.profile.profileResources.paymentTypes;

//   methods.map((object) => {
//     object.text = t('Action_.selectPaymentType')[object.Value];
//     object.value = object.Value;
//   });
//   return methods;
// });

// const isPostFormInProgress = computed(() => {
//   return this.$store.state.isPostFormInProgress;
// });
</script>
